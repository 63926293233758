@import "src/styles/_constants.scss";
.breadcrumb {
  display: flex;
  justify-content: center;
  > * {
    margin: 2%;
  }
  @media (max-width: $max-mobile) {
    a {
      width: 190px;
    }
  }
  .SearchBox {
    display: none;
  }
  @media (min-width: $min-with-separate-search-box) {
    .SearchBox {
      display: block;
    }
  }
}
