@import "src/styles/_constants.scss";
.btn {
  text-decoration: none;
  display: inline-block;
  padding: 1em;
}
.btn_primary {
  color: white;
  background-color: lighten($color: #000000, $amount: 0.1);
}

.button_row {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
  -ms-flex-pack: center;
  align-items: center;

  @media (max-width: $max-mobile) {
    display: none;
  }

  &_content {
    align-content: center;
  }
  a {
    width: 150px;
    margin-right: 20px;
    text-align: center;
    font-weight: 500;
    &:last-child {
      margin-right: 0;
    }
  }
}

.compare_title {
  margin: auto;
  text-align: center;
  margin-top: 40px;

  @media (max-width: $max-mobile) {
    display: none;
  }
}
.btn_selected {
  background-color: $color-primary;
  color: black;
}
