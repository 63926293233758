@import "src/styles/_constants.scss";
.flex_item {
  max-width: 100%;
  &.title {
    font-size: 1.3em;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    max-width: 30%;
  }

  &.item {
    max-width: 30%;

    &_title {
      vertical-align: middle;
      text-transform: uppercase;
      margin-left: 20px;
      font-size: 1.8em;
      @media (min-width: $min-not-mobile) {
        font-size: 2em;
        margin-left: 50px;
      }

      &_withDesc {
        margin-bottom: 0;
      }
    }
    &_description {
      margin-top: 0;
      font-weight: 500;
      margin-left: 20px;
      @media (min-width: $min-not-mobile) {
        font-size: 1.2em;
        margin-left: 50px;
      }
    }
  }
}
