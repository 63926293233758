@import "src/styles/_constants.scss";

.row_style {
  display: flex;
  align-items: stretch;
  &_padding {
    padding-bottom: 40px;
  }

  @media (max-width: $max-mobile) {
    justify-content: center;
  }

  .empty_cells_placeholder {
    border-right: 1px dotted gray;
    &:last-child {
      border: none;
    }
    padding: 1.5em;
    color: gray;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  > * {
    flex: 0 0 $column-width;
    max-width: $column-width; // IE: really?
    @media (max-width: $max-mobile) {
      flex: 0 0 $column-width-mobile;
      max-width: $column-width-mobile; // IE: really?
    }
  }
}
