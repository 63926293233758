@import "src/styles/_constants.scss";

.alphabetic {
  list-style: none;
  padding: 0;
  margin: 2em;
}

.alphabetic_headers {
  justify-content: center;
  display: flex;
  border-bottom: 1px solid black;
  margin-bottom: 1em;
  @media (max-width: $max-mobile) {
    border-bottom: none;
    margin-bottom: 0;
    h3 {
      margin-bottom: 6px;
    }
  }
}
.alphabetic_header {
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  span {
    background-color: $color-primary;

    border-radius: 50%;
    display: block;
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    font-weight: 400;
  }
}

.alphabetic_list {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  margin-bottom: 10px;

  &_container {
    justify-content: center;
  }

  &__item {
    width: 20%;
    @media only screen and (min-width: 1000px) {
      width: 20%;
    }
    @media only screen and (max-width: 900px) {
      width: 30%;
    }
    @media only screen and (max-width: 600px) {
      width: 60%;
    }
    @media only screen and (max-width: $max-mobile) {
      width: 100%;
    }

    word-break: break-word;
    margin-right: 1em;
    padding: 0.1em;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    &_label {
      @media (max-width: $max-mobile) {
        display: block;
        background-color: lightgrey;
        border-radius: 20px;
        margin-bottom: 4px;
        padding-top: 4px;
        padding-bottom: 4px;

        input {
          visibility: hidden;
        }
      }
      &_selected {
        @media (max-width: $max-mobile) {
          background-color: $color-primary;
        }
      }
    }

    label,
    input {
      cursor: pointer;
    }
  }
}

.compare_section {
  &_row {
    background-color: #fbe5d0;
    justify-content: center;
    display: flex;
    padding-bottom: 20px;
    &_item {
      background-color: $color-primary;
      color: black;
      text-decoration: none;
      padding: 12px;
      border-radius: 22px;
      width: 10em;
      text-align: center;
      @media (max-width: $max-mobile) {
        display: none;
      }
      svg {
        height: 1.2em;
        width: 1.2em;
        margin-bottom: -0.2em;
        margin-right: 5px;
      }
    }
  }
}

.mobile_row {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: $min-not-mobile) {
    display: none;
  }
}

.mobile_back_btn,
.mobile_sammenlign_btn {
  border-radius: 22px;
  border: 1px solid $color-primary;
  text-decoration: none;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  color: black;
}
.mobile_sammenlign_btn {
  background-color: white;
  border-color: black;
  @media (min-width: $min-not-mobile) {
    display: none;
  }
  svg {
    height: 1.2em;
    margin-bottom: -0.2em;
    margin-right: 5px;
  }
  &_disabled {
    background-color: #cccccc;
  }
}

.mobile_search {
  width: 80%;
  padding-bottom: 20px;
  margin: auto;
  @media (min-width: $min-not-mobile) {
    display: none;
  }
}
.sticky_header {
  position: sticky;
  top: 0px;
  z-index: 1010;
}
