@import "src/styles/_constants.scss";
.frontpage {
  &_header {
    text-align: center;
    color: gray;
  }
  &_options {
    display: flex;
    @media (max-width: 700px) {
      flex-direction: column;
    }
    justify-content: center;

    &_searchbox {
      margin: 2%;
      width: 20em;
      @media (max-width: $max-mobile) {
        width: 90%;
        margin: auto;
        margin-bottom: 15px;
      }
    }
    a {
      margin: 2%;
      @media (max-width: $max-mobile) {
        width: 80%;
        margin: auto;
        margin-top: 10px;
        padding: 10px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        font-size: 1em;
      }
    }
  }
}
