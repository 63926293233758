.selection {
  width: 100%;
  ul {
    padding: 0;
  }

  &_row {
    width: 80%;
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    align-content: center;

    &_item {
      display: flex;
      min-height: 2em;
      background-color: black;
      text-align: center;
      margin-left: 2px;
      margin-right: 10px;

      &:first-child {
        margin-left: 0px;
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 2px;
        margin-right: 0px;
      }
      &:only-child {
        margin-left: 0px;
        margin-right: 0px;
      }
      &_text {
        width: 85%;
        font-size: 1em;
        margin: auto;
        color: lightgray;
        margin-left: 10px;
      }

      span {
        margin-top: auto;
        margin-bottom: auto;
        background-color: inherit;
        &:before,
        &:after {
          background: white;
          width: 15px;
        }
      }

      &_close {
        text-align: center;
        width: 7%;
        padding: 2px;
        margin: auto;
        color: lightgray;

        background-color: black;
        border: none;
        cursor: pointer;
      }
    }
    li {
      list-style-type: none;
      padding-right: 10px;
      margin-bottom: 10px;
    }
  }
}
