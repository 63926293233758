.circle {
  stroke: black;
  &_filled {
    fill: #fff;
    &_male {
      fill: black;
    }
  }
  &_unfilled {
    fill: none;
  }
  &_female {
    stroke: #fff;
  }
}

.female_bar {
  fill: #84012e;
}

.male_bar {
  fill: #9bbad3;
}

.data_bar {
  fill: gray;
  opacity: 0.341;
}

.icon {
  stroke: #fff;
  &_woman {
    path {
      fill: #fff;
    }
  }
}
