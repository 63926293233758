@import "src/styles/_constants.scss";
.searchbox {
  position: relative;
  font-size: 1.2em;

  &_container {
    &_input {
      height: 1.9em;
      border-radius: 22px;
      border: 1px solid #dedede;
      padding: 4px 15px;
      width: 100%;
      font-size: inherit;
      &:focus {
        outline: none;
        border-color: $color-primary;
      }
    }
    svg {
      position: absolute;
      height: 1.3em;
      width: 1.3em;
      top: 5px;
      right: 8px;
      cursor: pointer;
    }
  }
  &_dropdown {
    // &_hidden {
    //   position: initial;
    //   height: 200px;
    //   width: 400px;
    // }
    position: absolute;
    z-index: 1021;
    top: 35px;
    width: 400px;

    @media (max-width: $max-mobile) {
      max-width: 100%;
    }

    background-color: white;
    padding: 0.1em 1em 1em 1em;
    border: 1px solid black;
    &_help {
      color: gray;
      font-weight: bold;
      text-transform: uppercase;
    }
    transition: opacity 0.5s ease-in-out;
    &_hide {
      // z-index: -1000;
      opacity: 0;
      pointer-events: none;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin: 0;
        padding: 0;
      }
    }
    &_header {
      text-transform: uppercase;
      background-color: lightgrey;
      margin: 0;
      padding: 0;
    }
    button {
      border: none;
      margin: 0;
      padding: 0.2em;
      width: 100%;
      text-align: left;
      background: transparent;
      color: inherit;
      font: inherit;
      &:hover {
        background-color: antiquewhite;
      }
      &.active {
        color: $color-primary;
      }
    }

    &_inline {
      position: unset;
      z-index: 1050;
      border: none;
      margin-top: 20px;
    }
  }
}
