@import "src/styles/_constants.scss";
.icon {
  &_close {
    float: right;
    cursor: pointer;
    background-color: black;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    margin-left: 8px;
    vertical-align: middle;

    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 80%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      margin-left: 2px;
      background: white;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &_close2 {
    float: right;
    cursor: pointer;
    border: 1px solid $color-primary;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    vertical-align: middle;
    background: white;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-primary;
      margin: auto 2px;
      height: 2px;
    }
  }

  &_open {
    cursor: pointer;
    border: 1px solid $color-primary;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-primary;
    }
    &:before {
      width: 2px;
      margin: 2px auto;
    }
    &:after {
      margin: auto 2px;
      height: 2px;
    }
  }
}
