@charset "UTF-8";
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.lonnVisualization_kjonncontainer {
  height: 230px;
  display: flex;
  align-items: center; }
  @media (max-width: 500px) {
    .lonnVisualization_kjonncontainer {
      height: unset;
      display: flex;
      justify-content: center; } }
  .lonnVisualization_kjonncontainer_nographics {
    display: block;
    text-align: center;
    align-items: center;
    height: 230px; }

.lonnVisualization_kjonn_nographics {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .lonnVisualization_kjonn_nographics .lonnVisualization_kjonn_text_kjønn {
    margin: auto; }
  .lonnVisualization_kjonn_nographics .lonnVisualization_kjonn_text_K,
  .lonnVisualization_kjonn_nographics .lonnVisualization_kjonn_text_M,
  .lonnVisualization_kjonn_nographics .lonnVisualization_kjonn_text {
    margin: auto; }

.lonnVisualization_kjonn_icon {
  margin-right: 5px; }
  .lonnVisualization_kjonn_icon svg {
    height: 1.5em; }

.lonnVisualization_kjonn_text_K, .lonnVisualization_kjonn_text_M, .lonnVisualization_kjonn_text {
  font-weight: 600; }
  .lonnVisualization_kjonn_text_K svg, .lonnVisualization_kjonn_text_M svg, .lonnVisualization_kjonn_text svg {
    height: 15px;
    width: 15px;
    margin-left: 5px; }

.lonnVisualization_kjonn_text_K {
  color: #84012e;
  display: flex; }
  .lonnVisualization_kjonn_text_K_iconContainer {
    background-color: #84012e;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    margin-top: 3px;
    margin-left: 5px; }
    .lonnVisualization_kjonn_text_K_iconContainer svg {
      height: 15px;
      margin-left: 3px;
      margin-bottom: 2px; }
      .lonnVisualization_kjonn_text_K_iconContainer svg path {
        fill: white; }

.lonnVisualization_kjonn_text_M {
  color: #9bbad3;
  display: flex; }
  .lonnVisualization_kjonn_text_M svg {
    margin-left: 2px !important;
    margin-top: 3px; }
