@import "src/styles/_constants.scss";
.percentagebar {
  //Scalable font size
  font-size: 1em;
  width: 100%;

  &_mainbar {
    border-radius: 1em;
    height: 1.2em;
    background-color: white;
    border: 2px solid #b1b1b1;
    overflow: hidden;
  }

  &_overlaybar {
    height: 100%;
    background-color: $color-primary;
  }

  &_percentage {
    font-size: 1.7em;
    font-weight: 500;
    margin-top: 0.4em;
    margin-left: 20px;
    &_main {
      font-size: 1.2em;
    }
    &_second {
      font-size: 0.6em;
    }
  }
}
