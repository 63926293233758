@import "src/styles/_constants.scss";
.visualizationheader {
  &_container {
    position: relative;
    &_header {
      background-color: white;
      &__title {
        text-align: center;
        text-transform: uppercase;
        display: flex;
        margin-left: 20px;

        h2 {
          margin-bottom: 10px;
          font-size: 1.8em;
          @media (min-width: $min-not-mobile) {
            font-size: 2em;
            margin-left: 50px;
          }
        }
        &_filter {
          text-transform: lowercase;
          padding-top: 10px;
          margin-left: 10px;
          @media (min-width: $min-not-mobile) {
            font-size: 1.2em;
            padding-top: 16px;
          }
          ul {
            display: inline-block;
            padding-left: 0;
            li {
              display: inline-block;
              margin-right: 3px;
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
        &_icon {
          &_non_widget {
            @media (min-width: $min-not-mobile) {
              display: none;
            }
          }
          cursor: pointer;
          border: 1px solid $color-primary;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: relative;
          margin: 4px;
          margin-left: 8px;
          display: inline-block;
          vertical-align: middle;
          min-width: 20px;
          margin-top: 28px;
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $color-primary;
          }
          &:before {
            width: 2px;
            margin: 2px auto;
          }
          &:after {
            margin: auto 2px;
            height: 2px;
          }
        }
      }
    }
    &_modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 0.8em;
      color: white;
      background-color: #3a3a3a;
      z-index: 1040;
      width: 100%;
      height: 100%;
      &_header {
        font-size: 1.2em;
        display: block;
        &_title {
          font-weight: 500;
          text-transform: uppercase;
          display: flex;
          &__desc {
            text-transform: lowercase;
          }
        }
        &_icon {
          cursor: pointer;
          border: 1px solid $color-primary;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: relative;
          margin: 4px;
          vertical-align: middle;
          background: white;
          margin-right: auto;
          margin-left: auto;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $color-primary;
            margin: auto 2px;
            height: 2px;
          }
        }
      }
      &_filters {
        font-size: 1.1em;
        ul {
          padding-left: 0;
        }
        li {
          list-style-type: none;
          input {
            margin-right: 5px;
          }
        }
        &_header {
          font-weight: 600;
          margin-bottom: 10px;
          position: relative;
          &_icon {
            margin-left: 6px;
            cursor: pointer;
          }
          &_helptext {
            position: absolute;
            background-color: white;
            display: flex;
            padding: 10px;
            border: 1px solid black;
            font-size: 0.9em;
            &_content {
              width: 80%;
              word-break: break-word;
            }
            &_closeIcon {
              width: 20%;
            }
          }
        }
      }
    }
  }
}
