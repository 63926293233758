@import "src/styles/_constants.scss";

$bar-width: 60px;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: right;
  // max-width: $column-width - $bar-width;
  @media (max-width: 500px) {
    margin-left: 0;
    text-align: center;
  }
  color: gray;
  &_data {
    &_header {
      font-weight: 500;
    }
    &_median {
      color: black;
      .container_data_text {
        font-weight: 700;
      }

      &_A {
        .container_data_text {
          color: black;
          font-weight: 700;
        }
      }
    }

    &_female {
      color: #84012e;
      font-weight: 600;
      word-break: break-all;

      &_icon {
        background-color: #84012e;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        margin-left: 5px;
        transform: translateY(2px);
        display: inline-block;
        svg {
          height: 15px;
          width: 15px;
          margin-left: 3px;
          margin-bottom: 2px;

          path {
            fill: white;
          }
        }
      }
    }
    &_male {
      color: #9bbad3;
      font-weight: 600;
      word-break: break-all;

      svg {
        height: 15px;
        width: 15px;
        margin-right: 3px !important;
        margin-left: 2px !important;
      }
    }
    &_text {
      font-weight: 600;
    }
  }
}
