.interessefilter {
  &_container {
    &_filters {
      display: flex;
      margin-bottom: 10px;
      margin-top: 10px;
      &_nullstill {
        margin-left: auto;
        cursor: pointer;
        span {
          background-color: inherit;
          margin-top: 2px;
          &:before,
          &:after {
            background: black;
          }
        }
      }
    }
    &_items {
      display: flex;
      flex-flow: row wrap;
      align-content: center;
      padding-bottom: 10px;

      &__item {
        width: 20%;
        @media only screen and (min-width: 1000px) {
          width: 18%;
        }
        @media only screen and (max-width: 900px) {
          width: 30%;
        }
        @media only screen and (max-width: 600px) {
          width: 60%;
        }
        @media only screen and (max-width: 500px) {
          width: 100%;
        }
        word-break: break-word;
        padding-right: 1em;
        label {
          cursor: pointer;
        }
      }
    }
  }
}
