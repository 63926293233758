.header {
  background-color: #ebe9e6;
  display: flex;
  width: 100%;
  height: 4em;
  justify-content: center;
  &_content {
    width: 80%;
    height: 100%;
  }
}

.languagepicker {
  display: flex;
  float: right;
  height: 100%;
}
