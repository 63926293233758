@import "src/styles/_constants.scss";
.tooltip {
  font-size: 1rem;
  &_helptext {
    display: inline;

    &_icon {
      border: none;
      background-color: inherit;
      color: $color-primary;
      margin-left: 4px;
      position: relative;
      z-index: 5;
      cursor: pointer;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
    }
    &_container {
      font-size: 0.8em;
      border: 1px solid black;
      background-color: white;
      color: black;
      width: 100%;
      position: absolute;
      z-index: 10;
      p {
        margin: 0.8em;
      }
      &__hidden {
        visibility: hidden;
      }
      &_icon {
        float: right;

        cursor: pointer;
        background-color: #fbe3b7;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        position: relative;
        margin: 4px;
        margin-left: 8px;
        vertical-align: middle;
        &::before,
        &::after {
          content: "";
          position: absolute;
          height: 2px;
          width: 80%;
          top: 50%;
          left: 0;
          margin-top: -1px;
          margin-left: 2px;
          background: #000;
        }
        &::before {
          transform: (rotate(45deg));
        }
        &::after {
          transform: (rotate(-45deg));
        }
      }
    }
  }
}
