@import "src/styles/_constants.scss";
.container {
  @media (max-width: $max-mobile) {
    display: none;
  }

  background-color: #cecece;
  margin-bottom: 20px;
  &_head {
    display: flex;
    font-weight: 500;
    font-size: 1.4em;
    padding: 10px;
    &_infotext {
      margin-right: 6px;
      color: #000066;
      text-decoration: underline;
    }
    &_upper {
      text-transform: uppercase;
    }

    &_icon {
      span {
        background-color: white;
        float: none;
        margin-left: 8px;
        display: inline-block;
      }
    }
    transition: box-shadow 0.1s ease-in-out;
    &:hover {
      box-shadow: 0px 10px 10px 0px #cecece;
    }
  }

  &_content,
  &_content_arbeidsledighet {
    background-color: #cecece;
    padding: 10px;
    padding-left: 20px;
    padding-top: 0;

    & > div:first-child {
      display: flex;
      justify-content: center;
    }
    ul {
      width: 16%;
    }

    &_arbeidsledighet {
      ul {
        width: 30%;
      }
    }
  }
}
