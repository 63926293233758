@import "src/styles/_constants.scss";
.selection {
  width: 100%;
  background-color: #e4e4e4;
  &_row {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    @media (max-width: $max-mobile) {
      justify-content: center;
      width: 100%;
      position: relative;
    }
    .searchbox {
      @media (max-width: $max-mobile) {
        display: none;
      }
    }
    &_selectors {
      width: 40%;
    }

    @media (min-width: 992px) {
      // display: flex;
      flex-flow: row wrap;
      align-content: center;
      // justify-content: space-between;
    }
  }
}

.btn {
  cursor: pointer;
  height: 34px;
  padding: 8px;

  background-color: white;

  color: black;
  border: solid 1px black;
  font-size: 1em;
  width: 10em;
  text-align: center;

  svg {
    position: relative;
    height: 1.2em;
    width: 1.2em;
    float: right;
  }
  &_interests {
    margin-left: 20px;
  }
}

.notdropdown {
  width: 80%;
  margin: auto;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  margin-bottom: 5px;
}

.unselected {
  background-color: lightgray;
}

.filterContainer {
  display: flex;
  .dropdown_container {
    position: relative;
    @media (max-width: $max-mobile) {
      position: unset;
    }
    .dropdown {
      position: absolute;
      width: 300px;
      background-color: white;
      z-index: 1020;

      @media (max-width: $max-mobile) {
        left: 0;
        width: 100%;
      }
    }
  }
}
