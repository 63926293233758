.arbeidsledighetvisualization {
  height: 360px;
  &_text {
    font-weight: 600;
  }
  &_percentage {
    margin-bottom: 20px;
  }
  &_noData {
    margin-bottom: 55px;
    margin-top: 20px;
  }
  &_kjonn {
    display: flex;
    width: 100%;
    &_icon {
      &_container {
        display: flex;
      }
      width: auto;
      svg {
        height: 1.5em;
      }
      margin-right: 5px;
      &_percentage {
        margin-bottom: 5px;
      }
    }
    &_container {
      width: 80%;
      &_text {
        font-weight: 500;
      }
    }
  }
}
