@import "src/styles/_constants.scss";

.button {
  display: block;
  border-radius: 200px;
  padding: 0.4em 0.9em;
  font-size: 0.8em;
  text-decoration: none;
  border: 2px solid transparent;
  text-align: center;
  &_dark {
    border-color: $color-primary;
    color: black;
  }
  &_light {
    border-color: $color-primary;
    color: black;
  }
  &_selected {
    background-color: $color-primary-light;
    border-color: $color-primary-light;
  }
}
