@import "src/styles/_constants.scss";
.secondheader {
  flex-flow: row;
  width: 100%;
  height: 6em;
  border-bottom: solid #d8d8d8 1px;
  padding-top: 20px;
  padding-bottom: 20px;

  &_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    width: 80%;
    height: 100%;
    input {
      height: 26px;
      width: 25em;
      border-radius: 15px;
      border: 1px solid #d8d8d8;
      padding: 5px;
    }

    &_item {
      color: $color-primary;
    }
  }
}
