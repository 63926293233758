.container {
  height: 360px;
  &_header {
    font-weight: 600;
  }
  &_andel {
    margin-bottom: 20px;
  }
  &_antall {
    display: flex;
    width: 100%;
    svg {
      height: 1.5em;
      margin-right: 10px;
    }
  }
  &_noData {
    margin-top: 20px;
    margin-bottom: 45px;
  }
}
